.onboarding-screen {
    background-color: var(--main-bg);
    // background-image: linear-gradient(-45deg, #4E21E6 20%, #BA84F5);
    // background-size: 100%;
    // background-repeat: repeat;
    color: #0C082F;
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    flex-direction: column;

    .heading{
        font-family: inherit;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.1;
        margin-bottom: 20px;
    }

    .subheading{
        font-size: 1rem;
        font-weight: 500;
        color: #333;
    }

    .subheading span{
        font-weight: 600;
    }

    .form-wrapper {
        max-width: 30rem;
        width: 100%;
    }

    .stretch-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .form {

        .form-group {
            position: relative;
            margin-top: 25px;

            label {
                cursor: pointer;
                font-weight: 600;
                font-size: 15px;
                color: #111;
                margin-bottom: 12px;
                letter-spacing: .25px;
            }

            .form-control {
                color: #181F2F !important;
                font-weight: 400;
                letter-spacing: .25px;
                background: #FFF !important;
                border: 1px solid #CCC !important;
                border-radius: .5rem !important;
                padding: 0.5rem 0.875rem !important;
                font-size: 15px !important;
                width: 100%;

                &::placeholder {
                    font-weight: 300;
                }

                &:active,
                &:hover,
                &:focus,
                &:focus-visible {
                    border: 1px solid #000 !important;
                    outline: none;
                }
            }

            input.form-control{
                height: 3rem !important;
            }

            &.lastname input {
                padding: 0.5rem 0.875rem !important;
            }

            &.phone input {
                &:active,
                &:hover,
                &:focus,
                &:focus-visible {
                    border: 0px solid #004679 !important;
                    outline: none;
                }
            }
        }

        .photo .photo-div > div > div > div > div > div{
            border-radius: 10px;
        }

        .btn-main-auth {
            border-radius: 1rem;
            background-color: #4E21E6;
            color: #FFF;
            transition: 350ms ease;
            font-size: 20px;
            font-weight: 300;
            text-align: center;
            border: 1px solid #BA84F5;
            padding: 1rem .75rem;
            line-height: 1;

            &:hover {
                box-shadow: rgb(76 78 100 / 100%) 0px 6px 18px -8px;
                background-color: #0E121B;
                color: #FFF;
            }
        }

        .nlp input {
            padding: 0.5rem 0.875rem 0.5rem 0.875rem !important;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #000 !important;
        -webkit-box-shadow: 0 0 0 30px #FFF inset !important;
    }
}
