
main.settings ._1Fq_tZ_l7SwH_r_a_SNOtA{
    font-family: 'Hanken Grotesk', sans-serif;
}

main.settings ._1Fq_tZ_l7SwH_r_a_SNOtA ._1Ou1tAFc1VCD4TNa0XwE7z {
    padding: 0%;
}

main.settings ._2oSbenaQCQZPXOD75Sj6nE {
    margin: 0;
}

main.settings .Icon--picture{
    display: none !important;
}

main.settings ._1MKAF96uXJHSW-a1nWoriQ {
    width: 100%;
    margin: 10px 0px 0px 0px;
}

main.settings ._1Fq_tZ_l7SwH_r_a_SNOtA ._10Kns8R3VdHSGFrESIz-4B{
    margin: 10px 0px 0px 0px;
}

main.settings .Icon--trash{
    fill: 'maroon';
}

._6IMSWtd3wtGGn2KgOcZIK{
    background-color: #FFF;
    padding: 10px 0px;
}

.profile-form{
    label{
        font-size: 14px;
        color: #555;
        font-weight: 300;
        margin-bottom: 0px;
    }

    h6{
        text-align: center;
        font-weight: 500;
    }

    input.form-control{
        height: 2.35rem;
    }

    button.btn-new{
        background: transparent;
        color: #0C082F;
        border: 1px solid #0C082F;
        font-size: 13px;
        padding: 0.7rem 2rem;

        &:hover{
            color: #FFF;
            background-color: #0C082F;
        }

        &.active{
            color: #FFF;
            background-color: #0C082F;
        }
    }

    button.btn-submit{
        background: #0C082F;
        color: #FFF;
        border: 1px solid #0C082F;
        font-size: 13px;
        padding: 0.7rem 2rem;

        &:hover{
            color: #FFF;
            background-color: #0C082F;
        }

        &.active{
            color: #FFF;
            background-color: #0C082F;
        }
    }

    .btn-change-password{
        background: var(--main-dark);
        border: 1px solid var(--main-dark);
    }
}