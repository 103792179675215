.auth-screens {
    background-color: #FFF;
    // background-image: linear-gradient(-45deg, #4E21E6 20%, #BA84F5);
    // background-size: 100%;
    // background-repeat: repeat;
    color: #0C082F;
    padding: 3rem;
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    flex-direction: column;

    .heading{
        font-family: inherit;
        font-size: 3rem;
        font-weight: 600;
    }

    .subheading{
        font-size: 1.25rem;
        color: #333;
    }

    .subheading span{
        font-weight: 600;
    }

    .form-wrapper {
        max-width: 30rem;
        width: 100%;
    }

    .stretch-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .section-right {
    }

    .section-right {
        .form-section{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            width: 100%;
            background: #FFF;
            border-radius: 15px;
            

            .btn-google,
            .btn-apple {
                width: 100%;
                text-align: left;
                font-size: 15px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                padding: 12px 10px;
                letter-spacing: 0.35px;
                color: #555;
                border: 1px solid #6c757d;
                border-radius: 0.575rem;
                background-color: #FFF;
                transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            }

            .btn-google:hover,
            .btn-apple:hover {
                background: #000;
                border: 1px solid #000;
                color: #FFF;
            }

            .btn-apple:hover img {
                filter: invert(1);
            }

            .btn-google span,
            .btn-apple span {
                margin-left: 10px;
            }

            div.or-divider {
                display: flex;
                align-items: center;
                margin: 30px 0px 30px 0px;
            }

            div.or-divider hr {
                flex-grow: 1;
                margin: 0;
            }

            div.or-divider span {
                padding: 0px 16px;
                color: #666;
            }

            .pills {
                display: flex;
                background: #EEF0F2;
                border-radius: 3rem;
                padding: 7px;
            }
            
            .pills button,
            .pills a {
                width: 50%;
                border-radius: 3rem;
                border: transparent;
                padding: 0.7rem;
                text-align: center;
                font-weight: 300;
                color: #777;
            }
            
            .pills button.active {
                background: #FFF;
                font-weight: 500;
                color: #000;
            }

            h3 {
                word-wrap: break-word;
                font-size: 2.375rem;
                line-height: 1.4;
                color: #111;
                font-weight: bold;
                letter-spacing: 0.75px;
                margin-bottom: 5px;
            }
    
            h6 {
                letter-spacing: normal;
                font-size: 1rem;
                font-weight: 400;
                color: #697586;
                margin-bottom: 10px;
            }
        }
    }

    form {

        hr {
            margin: 1.5rem 0rem;
            height: 0.01rem;
            background: #e3e8ef;
        }

        .form-group {
            position: relative;
            margin-bottom: 0px;

            label {
                cursor: pointer;
                font-weight: 600;
                font-size: 14px;
                color: #121926;
                margin-bottom: 12px;
                letter-spacing: .25px;

                svg {
                    color: #777;
                    margin-right: 10px;
                    margin-top: 5px;
                    transition: .25s;
                }
            }

            &.focus {
                label {
                    font-size: 15px;
                    top: -12px;
                    font-weight: 500;
                    color: #004679;
                }

                svg {
                    color: #004679;
                }
            }

            .input-icon {
                position: absolute;
                left: 0.7rem;
                bottom: 0.75rem;

                i {
                    font-size: 18px;
                    margin-top: 4px;
                }

                svg {
                    font-size: 22px;
                }
            }

            .PhoneInput {
                border: 1px solid #e3e8ef !important;
                border-radius: .5rem !important;
                padding-left: 0.875rem !important;

                &:hover {
                    border: 1px solid #004679 !important;
                }

                &.PhoneInput--focus {
                    border: 1px solid #004679 !important;
                }
            }

            nb input {
                border: 0px solid #e3e8ef !important;
            }

            input {
                color: #181F2F !important;
                font-weight: 400;
                letter-spacing: .25px;
                background: #FFF !important;
                border: 3px solid #e3e8ef !important;
                border-radius: .5rem !important;
                height: 3rem !important;
                padding: 0.5rem 0.875rem !important;
                font-size: 15px !important;
                width: 100%;

                &::placeholder {
                    font-weight: 300;
                }

                &:not(:disabled){

                    &:active,
                    &:hover,
                    &:focus,
                    &:focus-visible {
                        border: 3px solid #000 !important;
                        outline: none;
                    }
                }

                &:disabled{
                    opacity: .5;
                    background: #e1e1e1 !important;
                }
            }

            &.lastname input {
                padding: 0.5rem 0.875rem !important;
            }

            &.phone input {
                &:active,
                &:hover,
                &:focus,
                &:focus-visible {
                    border: 0px solid #004679 !important;
                    outline: none;
                }
            }
        }
        
        .fp {
            // color: #333;
            font-weight: 400;
            font-size: 14px;
        }

        .btn-main-auth {
            border-radius: 1rem;
            background-color: #4E21E6;
            color: #FFF;
            transition: 350ms ease;
            font-size: 20px;
            font-weight: 300;
            text-align: center;
            border: 1px solid #BA84F5;
            padding: 1rem .75rem;
            line-height: 1;

            &:hover {
                box-shadow: rgb(76 78 100 / 100%) 0px 6px 18px -8px;
                background-color: #0E121B;
                color: #FFF;
            }
        }

        .nlp input {
            padding: 0.5rem 0.875rem 0.5rem 0.875rem !important;
        }
    }

    .section-left {
        position: relative;

        .bg-overlay {
            height: 100vh;
            display: flex;
            flex-direction: column;
            position: relative;
            justify-content: space-between;
            padding: 5rem 3rem;
        }

        .wave {
            height: 100%;
            position: absolute;
            right: -80px;
            z-index: 9;
        }

        .section-left-content {
            h2 {
                color: #FFF;
                font-weight: 800;
                margin-bottom: 1.5rem;
                font-size: 2.4rem;
            }

            h5 {
                color: #f1f1f1;
                font-weight: 300;
                font-size: 1.3rem;
                word-spacing: 2px;
            }
        }
    }

    .radios {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .radio {
            width: 50%;

            input {
                pointer-events: none;
                visibility: hidden;

                &:focus + label {
                    background: transparent;
                }

                &:focus + label .checker {
                    border-color: var(--main-color);
                }

                &:checked + label .checker {
                    box-shadow: inset 0 0 0 4px #FFF;
                }

                &:checked + label {
                    background: var(--main-color-light);
                    border: 1px solid var(--main-color);
                    color: var(--main-color);
                }
            }

            label {
                border: 1px solid #e3e8ef;
                text-align: center;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 36px;
                border-radius: 8px;
                font-size: 16px;
                margin-top: -20px !important;
                margin-bottom: 0px !important;
                color: var(--main-color);
                padding: 26px 4px;
                cursor: pointer;
                transition: background-color 0.3s ease;
                background: transparent;

                &:hover {
                    background: var(--main-color-light);

                    .checker {
                        box-shadow: inset 0 0 0 2px #EDAE49;
                    }
                }

                .checker {
                    display: none;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    margin-right: 8px;
                    box-shadow: inset 0 0 0 2px #ccc;
                    transition: box-shadow 0.3s ease;
                }

                &.active {
                    background: #EDAE49;
                    color: #000;

                    .checker {
                        background: #EDAE49;
                        color: #000;
                        box-shadow: inset 0 0 0 2px #FFF;
                    }
                }
            }
        }
    }

    .text {
        color: hsla(0, 0%, 100%, 0.45);
        font-weight: 300;
        line-height: 2;
    }

    @media screen and (min-width: 786px) {
        body {
            overflow-y: hidden;
        }
    }

    @media screen and (max-width: 786px) {
        .login {
            max-width: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        body {
            background: var(--main-color);
        }

        form {
            h3 {
                font-size: 2.25rem;
                letter-spacing: 0.25px;
                margin-bottom: 0px;
            }

            h6 {
                font-size: 1.1rem;
                margin-bottom: 30px;
            }

            hr {
                display: none;
            }
        }

        .section-left-content {
            h2 {
                font-size: 1.15rem;
            }
        }

        .section-left {
            background: var(--main-color);
        }

        .section-right {
        }

        .bg-overlay {
            height: 100%;
            padding: 1.5rem .5rem .5rem .5rem;
        }

        .logo-sm {
            display: none;
        }

        .section-right form {
            background: #FFF;
            margin-bottom: .5rem;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #000 !important;
        -webkit-box-shadow: 0 0 0 30px #FFF inset !important;
    }
}

.terms {
    .content {
        h2 {
            color: black;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.75rem;
            margin-bottom: 0.25rem;
        }

        p {
            color: #666;
            font-size: 1rem;
            line-height: 1.8;
        }
    }
}

.privacy {
    .content {
        h2 {
            color: black;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.75rem;
            margin-bottom: 0.5rem;
        }

        p, ul {
            color: #666;
            font-size: 1rem;
            line-height: 1.5;
            margin-bottom: 1rem;
        }
    }
}
