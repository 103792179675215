#influencers.main-body{

    .datatable{

        td:first-child{
            padding: .25rem 1rem;
            padding-left: 0px;
        }

        .tdname {
            display: flex;
            align-items: center;

            img {
                height: 50px;
                width: 50px;
                border-radius: 50%;
                margin-right: 10px;
            }

            .img-placeholder {
                display: flex;
                height: 50px;
                width: 50px;
                justify-content: center;
                align-items: center;
                background-color: rgb(245 245 245);
                border-radius: 50%;
                margin-right: 10px;
            }

        }

    }

}