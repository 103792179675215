div[class^='react-scroll-to-bottom']::-webkit-scrollbar {
    width: 6px !important;
}

/* Track */
div[class^='react-scroll-to-bottom']::-webkit-scrollbar-track {
    background: rgb(229 229 229 / 1) !important;
}

/* Handle */
div[class^='react-scroll-to-bottom']::-webkit-scrollbar-thumb {
    background: #999 !important;
    border-radius: 10px;
}

/* Handle on hover */
div[class^='react-scroll-to-bottom']::-webkit-scrollbar-thumb:hover {
    background: #777 !important;
}

.active-user{
    /* background-color: ; */
}


@media only screen and (max-width: 768px) {
    .right-box{
        min-height: calc(100vh - 50px) !important;
        height: calc(100vh - 50px) !important;
    }
}
