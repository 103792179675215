#sidebar-wrapper {
    background: var(--main-bg);
    border-radius: 0px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    height: calc(100vh);
    width: 16rem;
    position: fixed;
    z-index: 2;
    // top: 15px;
    // left: 15px;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

.untoggled #sidebar-wrapper {
    overflow-y: scroll;
    overflow-x: hidden;
}

.untoggled #sidebar-wrapper::-webkit-scrollbar {
    width: 4px;
}

.untoggled #sidebar-wrapper:hover::-webkit-scrollbar-thumb {
    background: #CCC;
}

.untoggled #sidebar-wrapper::-webkit-scrollbar-thumb {
    background: transparent;
}

.untoggled #sidebar-wrapper::-webkit-scrollbar-thumb:hover {
    background: #f5f5f5;
}

#page-content-wrapper {
    overflow-y: hidden;
    height: 100%;
    min-height: 100vh;
    transition: margin .25s ease-out;
    margin-right: auto;

    nav {
        
        input#search-navbar{
            background: var(--highlight);
            border-radius: 10px;

            &:focus, &:hover{
                background: #D6D6FF90;
                outline: none;
                border: none;
            }

            &::placeholder{
                color: #999;
            }
        }

        .nav-right{
            font-size: 14px;
            font-weight: 500;

            .nav-quick-links{
                li{
                    padding: 0.375rem;
                    border-radius: .5em;
                    transition: .25s;
                    cursor: pointer;

                    &:hover{
                        background-color: var(--bg-hover);
                    }

                    &.active{
                        background-color: var(--bg-hover);
                    }
                }
            }

            .user-dropdown{
                font-size: 14px;
                font-weight: 500;
                transition: .25s;

                &:hover{
                    background-color: var(--bg-hover);
                }
            }

        }

    }

}

@media (min-width: 1200px) {
    #page-content-wrapper {
        max-width: 100%;
    }
}

@media (min-width: 1440px) {
    #page-content-wrapper {}
}

#page-content-wrapper .main-header {
    height: 125px;
    border-bottom: 1px solid #eae8f2;
}

#page-content-wrapper .main-body {
    min-height: calc(100vh - 60px);
    height: 100%;
    border-top-left-radius: 10px;
    background: #FFF;
    padding-top: 40px;
    padding-bottom: 20px;
}

#sidebar-wrapper .sidebar-heading img.logo {
    width: 120px;
    object-fit: contain;
    margin-bottom: 0px;
}

#sidebar-wrapper .sidebar-heading {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 1rem 1rem;
}

#sidebar-wrapper .nav-main i:first-child,
#sidebar-wrapper .nav-main svg:first-child {
    margin-right: 10px;
    // margin-top: -3px;
    opacity: 0.8;
}

#sidebar-wrapper .nav-main.active-link i:first-child,
#sidebar-wrapper .nav-main.active-link svg:first-child {
    opacity: 1;
}

#sidebar-wrapper .nav-main i {
    font-size: 15.5px;
    font-weight: 500;
}

#sidebar-wrapper .nav-main svg {
    stroke: #333333;
    // stroke-width: .3;
}

#sidebar-wrapper .nav-main.active-link svg {
    stroke: #5C2EE8;
    // stroke-width: .3;
}

#wrapper.toggled .hide-in-toggled {
    display: none;
}

#sidebar-wrapper .list-group {
    min-height: calc(100vh - 125px);
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

#sidebar-wrapper .list-group > div:first-child {
    margin-bottom: 50px;
    width: 100%;
    flex-shrink: 1;
    flex-grow: 1;
    height: auto;
    position: relative;
    overflow-y: hidden;
}

#sidebar-wrapper .list-group > div:nth-child(2) {
    margin-bottom: 5px;
}

#sidebar-wrapper .list-group > div {
    width: 100%;
}

#wrapper.toggled #sidebar-wrapper .list-group {
    padding-right: 0px !important;
}

.nav-main {
    position: relative;
    font-size: 16px;
    border-radius: 7.5px;
    font-weight: 400;
    padding: 14px 14px 14px 14px;
    color: #333;
    line-height: 1;
}

.nav-main:last-child {}

.untoggled .menu .nav-main {}

.untoggled .menu {}

.nav-main:hover {
    background: #E5E5FF;
}

.nav-main.collapsed:hover {
    background: #E5E5FF;
}

.nav-main:hover .nav-hide i {
    opacity: 1 !important;
}

#sidebar-wrapper .active-link {
    background: #E5E5FF;
    // color: #5C2EE8;
}

#sidebar-wrapper .nav-main.active-link * {}

.untoggled .menu .nav-main {
    display: flex;
    justify-content: space-between;
}

.untoggled .menu .nav-main.reports.collapsed div:nth-child(2) .notif,
.untoggled .menu .nav-main.settings.collapsed div:nth-child(2) .notif {
    display: block;
    margin-right: 5px;
}

.untoggled .menu .nav-main.reports div:nth-child(2) .notif,
.untoggled .menu .nav-main.settings div:nth-child(2) .notif {
    display: none;
}

.untoggled .menu .nav-main.reports div:nth-child(2),
.untoggled .menu .nav-main.settings div:nth-child(2) {
    display: flex;
    align-items: center;
}

#sidebar-wrapper .nav-main .notif-badge {
    width: 25px;
    height: 18px;
    padding: 4px;
    text-align: center;
    font-size: 12px;
    line-height: 10px;
    font-weight: 500;
    background: #FFF;
    border-radius: 20px;
    color: #000;
}

.toggled .nav-main .notif-badge {
    display: none;
}

#sidebar-wrapper .list-group p {
    color: #515161;
    font-weight: 600;
    font-size: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 8px;
    padding-left: 10px;
}

#sidebar-wrapper .badge-main {
    color: #FFF;
    background-color: #52459D;
    padding: 0.45em 0.7em;
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
}

#wrapper.toggled .nav-icon {
    display: block;
    text-align: right;
    padding-right: 10px;
}

#wrapper.toggled .nav-main {}

#wrapper.toggled .nav-hide {
    display: none;
}

.nav-hide {
    display: flex;
    align-items: center;
}

.nav-icon {
    display: none;
}

.nav-icon hr {
    display: none;
}

#wrapper.toggled .nav-icon hr {
    display: block;
    color: #e9e7f0;
    width: 30px;
    margin-left: auto;
    margin-right: -1px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.menu .submenu {
    margin-bottom: 10px;
}

.menu > .nav-main {
    color: #111;
}

.menu > .nav-main.collapsed {
    color: #333;
}

.menu .submenu .nav-main {
    border-bottom: 0;
    font-size: 14px;
}


.icon-main {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    font-size: 1.25rem;
    line-height: 1;
    overflow: hidden;
    user-select: none;
    border-radius: 8px;
    color: #52459D;
    background-color: rgba(82, 69, 157, 0.22);
}

.greeting-btn {
    border-radius: 8px;
    color: #000;
    transition: 350ms ease;
    font-size: 15px;
    line-height: 1em;
    font-weight: 600;
    text-align: center;
    padding: 0.35rem 0rem;
}

.greeting-btn img {
    margin-top: -5px;
    object-fit: fill;
    width: 15px;
    transform: rotate(90deg);
    max-height: 15px;
    padding-left: 0px;
}

// .btn-new {
//     display: flex;
//     align-items: center;
//     border-radius: 5px;
//     background-color: #FFF;
//     color: #111;
//     transition: 350ms ease;
//     font-size: 13px;
//     font-weight: 600;
//     text-align: center;
//     letter-spacing: 0.03em;
//     border: 1px solid #111;
//     padding: 0.45rem .9rem;
// }

// .btn-new:hover {
//     box-shadow: rgb(76 78 100 / 56%) 0px 6px 18px -8px;
//     background-color: #111;
//     color: #fff;
// }

.btn-new{
    display: flex;
    align-items: center;
    border-radius: 30px;
    background-color: var(--main-color);
    color: #FFF;
    transition: 350ms ease;
    font-size: 15px;
    line-height: 1em;
    font-weight: 400;
    padding: .8rem 1.5rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-new:hover{    
    background: #4E21E6;
}

.btn-submit {
    display: flex;
    align-items: center;
    border-radius: 30px;
    background-color: var(--main-color);
    color: white;
    transition: 350ms ease;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    text-align: center;
    border: 1px solid var(--main-color);
    padding: .85rem 1.25rem;
}

.btn-submit:disabled {
    cursor: no-drop !important;
    // opacity: .6;
    background-color: #e1e1e1;
    border: 1px solid #e1e1e1;
    color: #777;
}

.btn-cancel {
    border-radius: 30px;
    background-color: transparent;
    color: #c9c9c9;
    transition: 350ms ease;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    text-align: center;
    border: 1px solid #c9c9c9;
    padding: .85rem 1.25rem;
}

.btn-cancel:hover {
    background-color: #c9c9c9;
    border: 1px solid #c9c9c9;
    color: #FFF;
}

.btn-delete {
    border-radius: 30px;
    background-color: #FF2929;
    color: #FFF;
    transition: 350ms ease;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    text-align: center;
    border: 0px solid #c9c9c9;
    padding: .75rem 1.25rem;
}

.btn-delete:hover {
    background-color: #FF0A0A;
    color: #FFF;
}

.btn-delete-alt {
    border-radius: 30px;
    background-color: #FFF;
    color: #FF2929;
    transition: 350ms ease;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    text-align: center;
    border: 1px solid #FF2929;
    padding: .75rem 1.25rem;
}

.btn-delete-alt:hover {
    background-color: #FF0A0A;
    color: #FFF;
}

.btn-submit-inv {
    border-radius: 8px;
    background-color: #FFF;
    color: #111;
    transition: 350ms ease;
    font-size: 14px;
    line-height: 1em;
    font-weight: 500;
    text-align: center;
    border: 1px solid #111;
    padding: 1rem 1.25rem;
}

.btn-submit-inv:hover {
    box-shadow: rgb(76 78 100 / 56%) 0px 6px 18px -8px;
    background-color: #000;
    color: #fff;
}

#sidebar-wrapper .notification {
    position: relative;
}

#sidebar-wrapper .notification .notif {
    position: absolute;
    width: 100%;
}

#wrapper.untoggled #sidebar-wrapper .notification .notif-badge {
    position: absolute;
    width: 25px;
    height: 18px;
    top: 1px;
    right: 2px;
    padding: 4px;
    text-align: center;
    font-size: 12px;
    line-height: 10px;
    font-weight: 600;
    background: #FFF;
    border-radius: 20px;
    color: #000;
}

#wrapper.toggled #sidebar-wrapper .notification .notif-badge {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 5px;
    right: 3px;
    padding: 2px;
    text-align: center;
    font-size: 11px;
    line-height: 11px;
    font-weight: 600;
    background: red;
    border-radius: 50px;
    color: white;
}

div.view-notifications .single-notif {
    padding: 10px 10px 5px 10px;
    border-bottom: 1px solid #EBEBEB80;
}

div.view-notifications .single-notif:hover {
    background: #F7F7FF70;
}

div.view-notifications .single-notif.unread {
    background: #F7F7FF80;
}

div.view-notifications .single-notif .notif-title {
    font-size: 13px;
    line-height: 16px;
    color: #333;
    font-weight: 600;
}

div.view-notifications .single-notif .notif-txt {
    font-size: 12px;
    color: #888;
    font-weight: 500;
}

div.view-notifications .single-notif .notif-date {
    font-size: 10px;
    color: #ccc;
    font-weight: 500;
    text-align: right;
}

nav .navbar-icons div.view-notifications div.view-notif {
    position: absolute;
    right: 5px;
    top: 35px;
    width: 250px;
    max-height: 250px;
    overflow-y: scroll;
    border-radius: 10px;
    background: white;
    z-index: 2;
    box-shadow: rgb(76 78 100 / 20%) 0px 6px 15px -3px;
}

nav .navbar-icons div.view-notifications div.view-notif::-webkit-scrollbar {
    width: 4px;
}

nav .navbar-icons div.view-notifications div.view-notif::-webkit-scrollbar-thumb {
    background: #E0E0E0;
}

nav .navbar-icons div.view-notifications div.view-notif::-webkit-scrollbar-thumb:hover {
    background: #ccc;
}

nav .navbar-icons > div {
    padding-left: 5px;
    padding-right: 5px;
}

nav .navbar-icons img {
    height: 25px;
    width: 25px;
}

/** Stats */
.stat-card {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 250px;
    padding: 2rem 2rem 2rem 2rem;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
}

.stat-1 .sub-title {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.15px;
    color: #999;
    margin-bottom: 3px;
}

.stat-card .title {
    color: #000;
    font-weight: bold;
    font-size: 1.15rem;
}

.stat-card > .stat-title {
    margin-top: 5px;
    width: 100%;
    flex: 0 0 auto;
}

.stat-card > .stat-content {
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    align-items: flex-end;
}

.stat-card .icon-main-warning,
.stat-card .icon-main-danger {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    font-size: 1rem;
    line-height: 1;
    overflow: hidden;
    user-select: none;
}

.stat-card .icon-main-warning {
    background: #A6A6E2;
    color: #51449E;
    border-radius: 50%;
}

.stat-card .icon-main-danger {
    background: #A6A6E2;
    color: #FC2318;
    border-radius: 50%;
}

.stat-card h5 {
    margin-bottom: 5px;
    font-size: 20px;
}

.stat-card h5.text-warning {
    color: #ED8C00 !important;
}

.stat-card .btn-main-warning {
    width: 100%;
    padding: 0.45rem 0.3rem;
    border-radius: 40px;
    font-size: .8rem;
    font-weight: 600;
    color: #000;
    background-color: #FFD85F;
    border-color: #FFD85F;
}

.stat-card .btn-main-warning i {
    transform: rotate(-45deg);
}

.stat-card .btn-main-warning:hover {
    color: #FFF;
    background-color: #FFD825;
    border-color: #FFD825;
}

.stat-card .btn-main-success {
    width: 100%;
    padding: 0.45rem 0.3rem;
    border-radius: 40px;
    font-size: .8rem;
    font-weight: 600;
    color: #FFF;
    background-color: #257052;
    border-color: #257052;
}

.stat-card .btn-main-success:hover {
    color: #FFF;
    background-color: #1F5C43;
    border-color: #1F5C43;
}

.stat-1 .stat-content {
    display: flex;
    align-items: flex-end;
    margin-top: 50px;
    height: 66px;
    width: 100%;
}

.stat-1 .stat-content h6 {
    font-size: 14.5px;
    line-height: 22px;
    max-height: 66px;
    font-weight: 700;
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.stat-3 .stat-content {}

.stat-3 .stat-content h6 {
    font-size: 14px;
    line-height: 22px;
}

#sidebar-wrapper .user-profile {
    margin-left: 20px;
    bottom: 0;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: left;
    cursor: pointer;
    transition: 0.25s;
    width: 90%;
}

#sidebar-wrapper .user-profile img {
    height: 50px;
    width: 50px;
    padding: 10px;
    object-fit: contain;
    -webkit-box-shadow: 0 0 5px 2px #cccccc50;
    -moz-box-shadow: 0 0 5px 2px #cccccc50;
    box-shadow: 0 0 4px 2px #cccccc50;
    border: 1px solid #cccccc90;
    border-radius: 50%;
}

#sidebar-wrapper .user-profile > div {
    width: 65%;
    padding-left: 10px;
    padding-top: 3px;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

#sidebar-wrapper .user-profile div h6 {
    font-size: 14px;
    font-weight: 700;
    color: #222;
    margin-bottom: 2px;
}

#sidebar-wrapper .user-profile div p {
    font-size: 12px;
    font-weight: 500;
    color: #222;
    padding: 0;
    margin: 0;
    opacity: 0.7;
}

.tooltip {
    pointer-events: none;
}

/* Locked */

.nav-main.locked {
    position: relative;
}

.nav-main.locked .nav-hide:after {
    // content: url("../images/icons/svgs/lock-icon-inactive.svg");
    position: absolute;
    top: 6px;
    margin-left: 2px;
    width: 9px;
    height: 10px;
}


.page-head {
    margin-bottom: 30px;
}

.page-head h6 {}

.page-head h4 {
    line-height: 1;
    font-size: 34px;
    color: #000;
    font-weight: 600;
    margin-bottom: 8px;
}

.text-delete,
.text-edit {
    cursor: pointer;
    opacity: 0.7;
    font-weight: 500;
    padding: 0px 2px 0px 2px;
}

.text-edit {
    color: #0062cc !important;
}

.text-delete {
    color: #c82333 !important;
}

.text-delete:hover,
.text-edit:hover {
    opacity: 1;
}

@media (min-width: 768px) {

    #wrapper.untoggled .nav-main {
        margin-bottom: 5px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
        align-items: center;
    }

    #wrapper.untoggled .menu .submenu .nav-main {
        padding: 9px 0px 9px 45px;
    }

    #wrapper.toggled .nav-main {
        padding: 0;
        font-size: 0;
    }

    #wrapper.toggled .nav-icon {
        transition: 0.25s;
        border-radius: 0px;
        margin-left: auto;
        width: 3rem;
        display: block;
        text-align: right;
        padding: 17px 10px 17px 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    #wrapper.toggled .nav-icon:hover img {
        opacity: 1;
    }

    #wrapper.toggled .nav-main:last-child .nav-icon {
        border-bottom: 0px solid rgba(255, 255, 255, 0.1);
    }

    #wrapper.toggled .nav-main.active-link .nav-icon {
        background: rgba(255, 255, 255, 0.1);
    }

    #wrapper.toggled .nav-icon:hover {
        background: rgba(255, 255, 255, 0.1);
    }

    #wrapper.toggled .nav-icon.nav-icon-hr:hover {
        background: rgba(000, 000, 000, 0.0);
    }

    #wrapper.untoggled .nav-main .nav-icon img {
        width: 15px;
        height: 13px;
    }

    #wrapper.toggled .sidebar-heading {
        padding-right: 0px !important;
    }

    .main-body,
    .main-header {
        padding-left: 35px;
        padding-right: 35px;
    }

    .sidebar-heading,
    .list-group {}

    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        margin-left: calc(16rem);
        // width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -13rem;
    }

    #wrapper.toggled #page-content-wrapper {
        margin-left: 3rem;
    }

    #wrapper.untoggled .menu .nav-main .nav-icon svg {
        // content: url("../images/icons/svgs/platform-menuitems-right%20arrow.svg");
        transform: rotate(-90deg);
        width: 14px;
        height: 16px;
        opacity: 1;
        display: none;
    }

    #wrapper.untoggled .menu .nav-main .nav-icon .dropdown-arrow img {
        height: 18px;
        margin-right: 5px;
        transform: rotate(-90deg);
    }

    #wrapper.untoggled .menu .nav-main.collapsed .nav-icon .dropdown-arrow img {
        transform: rotate(90deg);
    }

    #wrapper.untoggled .menu .nav-main.collapsed .nav-icon svg {
        display: none;
    }

    #sidebar-wrapper .menu .nav-main.collapsed img:first-child {
        /* opacity: 0.7; */
    }

    #menu-toggle-sm {
        display: none;
    }

    #menu-toggle {
        transition: .25s;
        margin-top: -8px;
        cursor: pointer;
        width: 10px;
        height: 25px;
        object-fit: contain;
        // background: url("../images/icons/svgs/menu-small.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .d-flex.toggled #menu-toggle {
        margin-right: -5px;
        cursor: pointer;
        width: 25px;
        height: 15px;
        object-fit: contain;
        // background: url("../images/icons/svgs/menu-big.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

}

@media (max-width: 768px) {

    #wrapper.toggled .nav-main {
        padding: 12px 0px 12px 0px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    #wrapper.toggled .nav-main .nav-icon i {
        width: 15px;
        height: 13px;
    }

    #menu-toggle {
        margin-top: -8px;
        cursor: pointer;
        width: 30px;
        height: 45px;
        object-fit: contain;
        // background: url("../images/icons/svgs/menu-big.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .d-flex.toggled #menu-toggle {
        margin-right: -3px;
        cursor: pointer;
        width: 10px;
        height: 25px;
        object-fit: contain;
        // background: url("../images/icons/svgs/menu-small.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    #menu-toggle-sm {
        position: absolute;
        z-index: 1;
        filter: invert(1);
        cursor: pointer;
        width: 10px;
        height: 25px;
        top: 23px;
        left: 15px;
        object-fit: contain;
        // background: url("../images/icons/svgs/menu-small.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .page-head {
        margin-left: 1.5rem !important;
    }

    .d-flex.toggled #menu-toggle-sm {
        cursor: pointer;
        width: 30px;
        height: 45px;
        margin-right: 15px;
        object-fit: contain;
        // background: url("../images/icons/svgs/menu-big.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    #sidebar-wrapper {
        margin-left: -16rem !important;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: 0 !important;
        position: fixed;
    }

    #wrapper.toggled .nav-hide {
        display: block;
    }

    #wrapper.toggled .nav-main .nav-icon i {
        // content: url("../images/icons/svgs/platform-menuitems-right%20arrow.svg");
        width: 12px;
        height: 10px;
    }

    #page-content-wrapper .main-header {
        height: auto;
    }

    #wrapper.toggled .nav-icon hr {
        display: none;
    }
}

#wrapper.Admin{
    background: var(--main-dark);

    #sidebar-wrapper{
        background: var(--main-dark);
        color: #FFF;

        .list-group{

            .nav-main {
                color: #CCC;

                &.active-link {
                    background: #FFF;
                    color: var(--main-dark);
                    font-weight: 500;
                }

                &:not(.active-link):hover {
                    background: #3D3959;
                    
                    .nav-hide i {
                        opacity: 1 !important;
                    }
                }
                
                &.collapsed:hover {
                    background: #E5E5FF;
                }
            }
        }

    }

    #page-content-wrapper{ 
        
        nav {
        
            input#search-navbar{
                background: #3D3959;

                &:focus, &:hover{
                    background: #E5E5FF;
                    outline: none;
                    border: none;
                }
    
                &::placeholder{
                    color: #D6D6FF90;
                }
            }

            .user-dropdown{
                color: #CCC;

                &:hover{
                    color: #3D3959;
                }

                &.bg-hover{
                    color: #3D3959;
                }

                img{
                    border-color: #3D3959;
                }
            }
        
        }
    
    }
}