.main-body#monitor{
    .page-head {
        margin-top: 0px !important;
    }

    .table-wrapper {
      flex: 1;
      overflow-y: auto;
      position: relative;
      -ms-overflow-style: none; 
      scrollbar-width: none; 
    }
    
    .section-left {
        height: calc(100vh - 120px);
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
        position: relative;
    }
      
    .section-left::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background: linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
        pointer-events: none; 
    }
    
    .section-left > div {
        width: 100%;
    }

    .main-table{

        th:last-child,
        td:last-child {
            text-align: center;
        }
        td:first-child{
            font-size: 15px;
            color: #111;
        }

        td{
            font-size: 13px;
            color: #666;
        }
    }

    .table{

        td.Negative{
            color: #FF5C5C;
        }

        td.Neutral{
            color: #999;
        }

        td.Positive{
            color: #51C97F;
        }

        th{
            font-weight: 500;
        }
    }

    .Negative {
        color: #F2543D;
    }
    
    .Positive {
        color: #38C477;
    }
    
    .Neutral {
        color: #CCC;
    }

    .ave-table{
        thead{
            background: transparent;
        }
    }
 
    .apexcharts-canvas { 
        
        path{
            stroke-width: 8;
            stroke: var(--alt-color);
        }

        .apexcharts-datalabels{
            display: none;
        }
        
    }
    
    
    .btn-status {
        width: 100%;
        cursor: pointer;
        border: 1px solid #666;
        border-radius: 20px;
        padding: 8px 14px;
        font-weight: 600;
        font-size: 14px;

        &:hover{
            // background: var(--highlight);
            background: #D6D6FF90;
        }
    }

    .dropdown-menu{
        width: 100%;
        padding: 5px;
        position: absolute;
        z-index: 99;
        border: 1px solid #666;
        background: #FFF;
        border-radius: 0px 0px 10px 10px;
        font-weight: 600;
        font-size: 12px;
        // max-height: 130px;
        // overflow-y: scroll;

        .dropdown-item{
            padding: 8px 16px;
            cursor: pointer;
        }

        .dropdown-item:hover{
            border-radius: 8px;
            background: #E7E6FF;
        }
    }
}