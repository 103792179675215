.datatable {
    
    .table{
        width: 100%;
        // border: 1px solid #e3e8ef;
        border-radius: 5px;
    }

    .table-wrapper{

        .table-search{
            height: auto;
            min-height: 2rem;
            border: 0.0625rem solid #e3e8ef;
            color: #121926;
            border-radius: 0.25rem;
            padding: 0.25rem 0.5rem;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            transition: all .3s;
        }

        .table-search:focus-visible, .table-search:focus, .table-search:hover {
            border-color: #004679;
            box-shadow: none;
            color: #121926;
            outline: none;
        }

        select.rows{
            padding: 8px 8px;
            background-color: #f0f0f0;
            font-size: 12px;
            border-radius: 5px;
        }

        .record-range{
            font-size: 14px;
            letter-spacing: .25px;
        }

        .pagination{
            display: flex;
        }

        .pagination button{
            padding: 5px 3px;
            background-color: #f0f0f0;
            border: 1px solid #FFF;
            font-size: 12px;
            border-radius: 5px;
        }

        .pagination button.cur{
            padding: 5px 7px;
            letter-spacing: .25px;
            font-weight: 500;
        }

        .table th {
            border-bottom: 1px solid var(--main-dark);
            font-size: 12px;
            font-weight: 500;
            letter-spacing: .25px;
            color: var(--main-dark);
            padding: .5rem 1rem;
            text-align: left;
        }

        .table td {
            color: var(--main-dark);
            border-bottom: 0.0625rem solid var(--main-dark-light);
            font-weight: 500;
            font-size: 14px;
            letter-spacing: normal;
            padding: 2rem 1rem;
        }
    
        th:first-child,
        td:first-child {
            transition: .25s;
            text-align: left;
            padding-left: 0px;
        }

        th:last-child,
        td:last-child {
            text-align: right;
            padding-right: 0px;
        }

    }

    .tbl-details{
        width: 100%;
        border: 1px solid #f0f0f0;
    }

    .tbl-details th {
        border-bottom: 1px solid #f0f0f0;
        font-size: .75rem;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: .25px;
        color: #555;
        padding: .75rem 1rem;
        text-align: left;
    }

    .tbl-details td {
        color: #999;
        border-bottom: 0.0625rem solid #f0f0f0;
        font-weight: 500;
        font-size: .9rem;
        letter-spacing: normal;
        padding: .75rem 1rem;
    }
    

    @media screen and (max-width: 768px) {
        .table{
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }

        .table td, .table th{
            white-space: nowrap;
            vertical-align: middle;
        }
    }

}