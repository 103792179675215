.main-body#campaigns{
    .page-head {
        margin-top: 0px !important;
    }
    
    .btn-status {
        width: 100%;
        cursor: pointer;
        border: 1px solid #666;
        border-radius: 20px;
        padding: 8px 14px;
        font-weight: 500;
        font-size: 14px;

        &:hover{
            // background: var(--highlight);
            background: #D6D6FF90;
        }
    }

    .status-dropdown{
        width: 100%;
        padding: 5px;
        position: absolute;
        z-index: 99;
        border: 1px solid #666;
        background: #FFF;
        border-radius: 0px 0px 10px 10px;
        font-weight: 400;
        font-size: 12px;
        // max-height: 130px;
        // overflow-y: scroll;

        .dropdown-item{
            padding: 8px 16px;
            cursor: pointer;
        }

        .dropdown-item:hover{
            border-radius: 8px;
            background: #E7E6FF;
        }
    }

    table td{
        text-align: center;
        font-weight: 500;
    }
}

.main-body#campaign-details{

    .details-section{
        height: calc(100vh - 120px);
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
        position: relative;

        .stat{
            .btn-main-alt{
                font-size: 12px;
            }
        }
    }

    .campaign-lists{

        &::-webkit-scrollbar {
            height: 10px;
            border-radius: 10px;
            background: #E9E9EE;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #D1D1DC;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #AEAEC1;
        }

        .list-body{
            flex: 1 1;
            display: flex;
            height: calc(100% - 80px);
            flex-direction: column;
            overflow-y: auto;
            padding-bottom: 30px;

            &::after{
                content: "";
                position: absolute;
                bottom: 10px;
                left: 0;
                width: 100%;
                height: 10%;
                background: linear-gradient(to top, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
                pointer-events: none;
            }

            &::-webkit-scrollbar {
                width: 0px;
            }

            > div{
                flex: 1 1;
                height: 100%;
            }
        }

        .inf-card-sm { 

            .profile{
                height: 40px;
                width: 40px;
                border-radius: 40px;
            }
        
            .img-placeholder {
                display: flex;
                height: 40px;
                width: 40px;
                justify-content: center;
                align-items: center;
                background-color: rgb(245 245 245);
                border-radius: 50%;
            }
        
            .img-placeholder span{
                font-size: 20px;
            }

            .social-icons{
                img{
                    height: 13px;
                    width: 13px;
                }
            }

        }
        
    }
}