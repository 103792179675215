.main-body#team{
    .page-head {
        margin-top: 0px !important;
    }

    table{
        td{
            font-size: 14px;
        }

        td.name{
            font-size: 16px;
        }

        .resend-invitation{
            background-color: #FFF;
            color: #0C082F;
            border: 1px solid #0C082F;
            font-size: 13px;
            padding: 0.5rem 1.25rem;

            &:hover{
                color: #FFF;
                background-color: #0C082F;
            }

            &.active{
                color: #FFF;
                background-color: #0C082F;
            }
        }
    }
    
    .btn-status {
        width: 100%;
        cursor: pointer;
        // border: 1px solid #666;
        border-radius: 20px;
        padding: 8px 20px;
        font-weight: 500;
        font-size: 13px;
        background-color: var(--main-bg);
        border: 1px solid #D6D6FF90;
        position: relative;

        .dot{
            position: absolute;
            left: 10px;
        }

        &:hover{
            // background: var(--highlight);
            background: #D6D6FF90;
        }
    }

    .status-dropdown{
        width: 100%;
        padding: 5px;
        position: absolute;
        z-index: 99;
        border: 1px solid #666;
        background: #FFF;
        border-radius: 0px 0px 10px 10px;
        font-weight: 500;
        font-size: 12px;
        // max-height: 130px;
        // overflow-y: scroll;

        .dropdown-item{
            padding: 8px 16px;
            cursor: pointer;
        }

        .dropdown-item:hover{
            border-radius: 8px;
            background: #E7E6FF;
        }
    }
}